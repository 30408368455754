import React from 'react';
import {useAuth} from '../services/use-auth';
import {signOut} from '@firebase/auth';
import {ConfirmAction} from '../assets/controls';

const SignOutModal = (props) => {
  const authHook = useAuth();
  const signOutFunc = () => {
    signOut(authHook.auth).catch((error) => {
      console.error(error.message);
    });
    props.handleClose();
  };

  return (
    <ConfirmAction
      dataForAction={true}
      callback={(data) => signOutFunc(data)}
      openState={props.open}
      handleCloseState={props.handleClose}>
      Sign Out as {props.userData.username}
    </ConfirmAction>
  );
};

export default SignOutModal;
