import React, {useState, memo} from 'react';
import {createUserWithEmailAndPassword} from 'firebase/auth';
import {useAuth} from '../services/use-auth';
import {doc, setDoc} from 'firebase/firestore';
import styled from '@emotion/styled';
import {useNavigate} from 'react-router-dom';

import * as ROUTES from '../constants/routes';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import StyledButton from '../assets/buttons';

import useProgressiveImg from '../assets/progressiveImage';
import lowResBRImg from '../assets/images/lowResLoginBackground.jpg';
import fullResBRImg from '../assets/images/fullResLoginBackground.jpg';
import {filterStyle} from '../assets/controls';

const BackgroundImage = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  ${filterStyle}
  filter: ${(props) => (props.blur ? 'blur(10px)' : 'none')};

  /* object-position: 0% 90%; */
  transform: scale(1.03);
  object-fit: cover;
  z-index: -2;
`;

const BackgroundImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
`;

const SignInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.92);
  padding: 20px;
  border-radius: 8px;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15vh;
`;

const TextButton = styled.p`
  font-family: 'MontserratMed';
  font-size: 18px;
  font-style: strong;
  cursor: pointer;
`;

const Title = styled.p`
  font-size: 26px;
  font-family: 'MontserratMed';
`;

const SignUpComponent = () => {
  const authHook = useAuth();
  const navigate = useNavigate();

  const [signUpData, setSignUpData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: '',
    passwordConfirm: '',
    phoneDisplay: '',
  });
  const [entryErrors, setEntryErrors] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    password: false,
    passwordConfirm: false,
  });
  const [entryFeedback, setEntryFeedback] = useState({
    firstName: null,
    lastName: null,
    phone: null,
    password: null,
    passwordConfirm: null,
  });

  const handleChange = (event) => {
    setSignUpData((signUpData) => ({
      ...signUpData,
      [event.target.name]: event.target.value,
    }));
  };

  const handlePhoneChange = (event) => {
    setSignUpData((signUpData) => ({
      ...signUpData,
      [event.target.name]: event.target.value,
    }));
  };

  const cleanPhone = () => {
    if (signUpData.phone.length === 10) {
      const raw = signUpData.phone;
      const areaCode = raw.substring(0, 3);
      const threeDig = raw.substring(3, 6);
      const lastDig = raw.substring(6, 10);
      const altered = `(${areaCode}) ${threeDig}-${lastDig}`;
      setSignUpData((signUpData) => ({
        ...signUpData,
        phone: altered,
      }));
    }
  };

  const validateFirstName = () => {
    if (signUpData.firstName === '') {
      setEntryErrors((entryErrors) => ({...entryErrors, firstName: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        firstName: 'You must enter a First Name',
      }));
    } else {
      const containsTwo = signUpData.firstName.includes(' ');
      if (containsTwo) {
        setEntryErrors((entryErrors) => ({...entryErrors, firstName: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          firstName: 'You must enter only one name with no spaces',
        }));
      } else {
        setEntryErrors((entryErrors) => ({...entryErrors, firstName: false}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          firstName: null,
        }));
      }
    }
  };

  const validateLastName = () => {
    if (signUpData.lastName === '') {
      setEntryErrors((entryErrors) => ({...entryErrors, lastName: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        lastName: 'You must enter a Last Name',
      }));
    } else {
      const containsTwo = signUpData.lastName.includes(' ');
      if (containsTwo) {
        setEntryErrors((entryErrors) => ({...entryErrors, lastName: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          lastName: 'Please hyphenate multiple last names',
        }));
      } else {
        setEntryErrors((entryErrors) => ({...entryErrors, lastName: false}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          lastName: null,
        }));
      }
    }
  };

  const validateEmail = () => {
    if (signUpData.email === '') {
      setEntryErrors((entryErrors) => ({...entryErrors, email: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        email: 'You must enter a email',
      }));
    } else {
      const containsAtAndPeriod =
        signUpData.email.includes('@') && signUpData.email.includes('.');
      if (!containsAtAndPeriod) {
        setEntryErrors((entryErrors) => ({...entryErrors, email: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          email: 'You must enter a valid email address',
        }));
      } else {
        setEntryErrors((entryErrors) => ({...entryErrors, email: false}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          email: null,
        }));
      }
    }
  };

  const validatePassword = () => {
    if (signUpData.password === '') {
      setEntryErrors((entryErrors) => ({...entryErrors, password: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        password: 'You must enter a password',
      }));
    } else {
      const longerThan8 = signUpData.password.length > 8;
      if (!longerThan8) {
        setEntryErrors((entryErrors) => ({...entryErrors, password: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          password: 'Passwords must be longer than 8 characters',
        }));
      } else {
        setEntryErrors((entryErrors) => ({...entryErrors, password: false}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          password: null,
        }));
      }
    }
  };

  const validatePasswordConfirm = () => {
    if (signUpData.passwordConfirm === '') {
      setEntryErrors((entryErrors) => ({
        ...entryErrors,
        passwordConfirm: true,
      }));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        passwordConfirm: 'You must enter a password',
      }));
    } else {
      const match = signUpData.password === signUpData.passwordConfirm;
      if (!match) {
        setEntryErrors((entryErrors) => ({
          ...entryErrors,
          passwordConfirm: true,
        }));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          passwordConfirm: 'Your passwords do not match',
        }));
      } else {
        setEntryErrors((entryErrors) => ({
          ...entryErrors,
          passwordConfirm: false,
        }));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          passwordConfirm: null,
        }));
      }
    }
  };

  const validatePhone = () => {
    if (signUpData.phone === '') {
      setEntryErrors((entryErrors) => ({...entryErrors, phone: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        phone: 'You must enter a phone number',
      }));
    } else {
      const notLongEnough = signUpData.phone.length < 10;
      console.log(`${signUpData.phone.length}`);
      if (notLongEnough) {
        setEntryErrors((entryErrors) => ({...entryErrors, phone: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          phone: 'Include a complete phone number with area code',
        }));
        console.log('not long enough');
      } else {
        const tooLong = signUpData.phone.length > 10;
        if (tooLong) {
          setEntryErrors((entryErrors) => ({...entryErrors, phone: true}));
          setEntryFeedback((entryFeedback) => ({
            ...entryFeedback,
            phone: 'Include only a 10 digit phone number',
          }));
        } else {
          if (isNaN(signUpData.phone)) {
            setEntryErrors((entryErrors) => ({...entryErrors, phone: true}));
            setEntryFeedback((entryFeedback) => ({
              ...entryFeedback,
              phone: 'Include only numbers',
            }));
          } else {
            setEntryErrors((entryErrors) => ({...entryErrors, phone: false}));
            setEntryFeedback((entryFeedback) => ({
              ...entryFeedback,
              phone: null,
            }));
          }
        }
      }
    }
  };

  const invalid =
    signUpData.email === '' ||
    signUpData.password === '' ||
    signUpData.passwordConfirm === '' ||
    signUpData.phone === '' ||
    signUpData.firstName === '' ||
    signUpData.lastName === '' ||
    entryErrors.email ||
    entryErrors.password ||
    entryErrors.passwordConfirm ||
    entryErrors.phone ||
    entryErrors.firstName ||
    entryErrors.lastName;

  const submitSignUp = async () => {
    console.log(`Starting to sign up user ${signUpData.firstName}`);
    const purifyStr = (str) => {
      const lower = str.toLowerCase();
      const fixed = lower.charAt(0).toUpperCase() + lower.slice(1);
      return fixed;
    };
    const firstName = purifyStr(signUpData.firstName);
    const lastName = purifyStr(signUpData.lastName);
    const username = firstName.concat(' ').concat(lastName);

    try {
      const userCredential = await createUserWithEmailAndPassword(
        authHook.auth,
        signUpData.email,
        signUpData.password,
      );
      const user = userCredential.user;
      console.log(
        `Creating a user with the username ${username} and user ID: ${user.uid}`,
      );
      await setDoc(
        doc(authHook.db, 'users', user.uid),
        {
          email: user.email,
          username: username,
          phoneNumber: signUpData.phone,
          firstName: signUpData.firstName,
          lastName: signUpData.lastName,
          onboarded: false,
          roles: {
            admin: false,
            owner: false,
            subscriber: false,
            superAdmin: false,
          },
        },
        {merge: true},
      );
      navigate(ROUTES.HOME);
    } catch (error) {
      console.error(error.message);
      console.error(error.code);
    }
  };

  return (
    <React.Fragment>
      <PageWrapper>
        <SignInWrapper>
          <Title>Sign Up</Title>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pr: 3,
              pl: 3,
              pb: 3,
              gap: 4,
            }}
            noValidate
            autoComplete="off">
            <TextField
              sx={{width: '350px', fontSize: '22px'}}
              label="First Name"
              name="firstName"
              variant="outlined"
              color="secondary"
              value={signUpData['firstName']}
              onChange={handleChange}
              onBlur={() => validateFirstName()}
              error={entryErrors.firstName}
              helperText={entryFeedback.firstName}
            />
            <TextField
              sx={{width: '350px', fontSize: '22px'}}
              label="Last Name"
              name="lastName"
              variant="outlined"
              color="secondary"
              value={signUpData['lastName']}
              onChange={handleChange}
              onBlur={() => validateLastName()}
              error={entryErrors.lastName}
              helperText={entryFeedback.lastName}
            />
            <TextField
              sx={{width: '350px', fontSize: '22px'}}
              label="Phone Number"
              name="phone"
              variant="outlined"
              color="secondary"
              value={signUpData['phone']}
              onChange={handlePhoneChange}
              onBlur={() => {
                validatePhone();
                cleanPhone();
              }}
              onFocus={() => {
                setSignUpData((signUpData) => ({...signUpData, phone: ''}));
              }}
              error={entryErrors.phone}
              helperText={entryFeedback.phone}
            />
            <TextField
              sx={{width: '350px', fontSize: '22px'}}
              label="Email Address"
              name="email"
              variant="outlined"
              color="secondary"
              value={signUpData['email']}
              onChange={handleChange}
              onBlur={() => validateEmail()}
              error={entryErrors.email}
              helperText={entryFeedback.email}
            />
            <TextField
              sx={{width: '350px', fontSize: '22px'}}
              label="Password"
              type="password"
              name="password"
              variant="outlined"
              color="secondary"
              value={signUpData['password']}
              onChange={handleChange}
              onBlur={() => validatePassword()}
              error={entryErrors.password}
              helperText={entryFeedback.password}
            />
            <TextField
              sx={{width: '350px', fontSize: '22px'}}
              label="Confirm Password"
              name="passwordConfirm"
              type="password"
              variant="outlined"
              color="secondary"
              value={signUpData['passwordConfirm']}
              onChange={handleChange}
              onBlur={() => validatePasswordConfirm()}
              error={entryErrors.passwordConfirm}
              helperText={entryFeedback.passwordConfirm}
            />
          </Box>
          <StyledButton
            disabled={invalid}
            primary
            onClick={submitSignUp}
            width="350px"
            fontSize="26px">
            Sign Up!
          </StyledButton>
          <TextButton onClick={() => navigate(ROUTES.SIGN_IN)}>
            Already have an account?
          </TextButton>
        </SignInWrapper>
      </PageWrapper>
    </React.Fragment>
  );
};

const BlurredUpBackground = memo(({src, blur, cancelAnimate}) => {
  return (
    <BackgroundImageWrapper>
      <BackgroundImage blur={blur} cancelAnimate={cancelAnimate} src={src} />
    </BackgroundImageWrapper>
  );
});

const SignUpPage = (props) => {
  const [src, {blur, cancelAnimate}] = useProgressiveImg(
    lowResBRImg,
    fullResBRImg,
  );
  return (
    <React.Fragment>
      {!props.stillLoading && (
        <React.Fragment>
          <BlurredUpBackground
            src={src}
            blur={blur}
            cancelAnimate={cancelAnimate}
          />
          <SignUpComponent />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default SignUpPage;
