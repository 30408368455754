import React, {useState, memo} from 'react';
import {useAuth} from '../services/use-auth';
import {signInWithEmailAndPassword} from '@firebase/auth';
import styled from '@emotion/styled';
import * as ROUTES from '../constants/routes';
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import StyledButton from '../assets/buttons';

import useProgressiveImg from '../assets/progressiveImage';
import lowResBRImg from '../assets/images/lowResLoginBackground.jpg';
import fullResBRImg from '../assets/images/fullResLoginBackground.jpg';
import {BackgroundImage, BackgroundImageWrapper} from '../assets/globalStyles';

const SignInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.92);
  padding: 20px;
  border-radius: 8px;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15vh;
`;

const TextButton = styled.p`
  font-family: 'MontserratMed';
  font-size: 18px;
  font-style: strong;
  cursor: pointer;
`;

const Title = styled.p`
  font-size: 26px;
  font-family: 'MontserratMed';
`;

const SignInComponent = () => {
  const authHook = useAuth();
  const navigate = useNavigate();

  const [loginData, setLoginData] = useState({email: '', password: ''});
  const [entryErrors, setEntryErrors] = useState({
    email: false,
    password: false,
  });
  const [entryFeedback, setEntryFeedback] = useState({
    email: null,
    password: null,
  });

  const handleChange = (event) => {
    setLoginData((loginData) => ({
      ...loginData,
      [event.target.name]: event.target.value,
    }));
  };

  const validateEmail = () => {
    if (loginData.email === '') {
      setEntryErrors((entryErrors) => ({...entryErrors, email: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        email: 'You must enter a email',
      }));
    } else {
      const containsAtAndPeriod =
        loginData.email.includes('@') && loginData.email.includes('.');
      if (!containsAtAndPeriod) {
        setEntryErrors((entryErrors) => ({...entryErrors, email: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          email: 'You must enter a valid email address',
        }));
      } else {
        setEntryErrors((entryErrors) => ({...entryErrors, email: false}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          email: null,
        }));
      }
    }
  };

  const validatePassword = () => {
    if (loginData.password === '') {
      setEntryErrors((entryErrors) => ({...entryErrors, password: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        password: 'You must enter a password',
      }));
    } else {
      const longerThan8 = loginData.password.length > 8;
      if (!longerThan8) {
        setEntryErrors((entryErrors) => ({...entryErrors, password: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          password: 'Passwords are longer than this',
        }));
      } else {
        setEntryErrors((entryErrors) => ({...entryErrors, password: false}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          password: null,
        }));
      }
    }
  };

  const submitSignIn = () => {
    signInWithEmailAndPassword(
      authHook.auth,
      loginData.email,
      loginData.password,
    )
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        navigate(ROUTES.HOME);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorMessage);
      });
  };

  const invalid =
    loginData.username === '' ||
    loginData.password === '' ||
    entryErrors.username ||
    entryErrors.password;

  return (
    <React.Fragment>
      <PageWrapper>
        <SignInWrapper>
          <Title>Sign In</Title>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              pb: 3,
              pl: 2,
              pr: 2,
            }}
            noValidate
            autoComplete="off">
            <TextField
              sx={{width: '350px', fontSize: '22px'}}
              label="Email Address"
              name="email"
              variant="outlined"
              color="secondary"
              value={loginData['email']}
              onChange={handleChange}
              onBlur={() => validateEmail()}
              error={entryErrors.email}
              helperText={entryFeedback.email}
            />
            <TextField
              sx={{width: '350px', fontSize: '22px'}}
              label="Password"
              type="password"
              name="password"
              variant="outlined"
              color="secondary"
              value={loginData['password']}
              onChange={handleChange}
              onBlur={() => validatePassword()}
              error={entryErrors.password}
              helperText={entryFeedback.password}
            />
          </Box>
          <StyledButton
            disabled={invalid}
            primary
            onClick={submitSignIn}
            width="250px"
            fontSize="26px">
            Login
          </StyledButton>
          <TextButton onClick={() => navigate(ROUTES.SIGN_UP)}>
            Don't have an account?
          </TextButton>
        </SignInWrapper>
      </PageWrapper>
    </React.Fragment>
  );
};

const BlurredUpBackground = memo(({src, blur, cancelAnimate}) => {
  return (
    <BackgroundImageWrapper>
      <BackgroundImage blur={blur} cancelAnimate={cancelAnimate} src={src} />
    </BackgroundImageWrapper>
  );
});

const SignInPage = (props) => {
  const [src, {blur, cancelAnimate}] = useProgressiveImg(
    lowResBRImg,
    fullResBRImg,
  );
  return (
    <React.Fragment>
      {!props.stillLoading && (
        <React.Fragment>
          <BlurredUpBackground
            src={src}
            blur={blur}
            cancelAnimate={cancelAnimate}
          />
          <SignInComponent />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default SignInPage;
