//React component to manage workouts
import React, {useEffect, useState, memo} from 'react';
import {useNavigate} from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import styled from '@emotion/styled';
import StyledButton from '../assets/buttons';
import {useAuth} from '../services/use-auth';
import {
  collection,
  deleteDoc,
  onSnapshot,
  doc,
  setDoc,
} from 'firebase/firestore';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import {ConfirmAction} from '../assets/controls';

import AddOrChangeWorkout from './addWorkout.jsx';

import {
  BackgroundImage,
  BackgroundImageWrapper,
  PageWrapper,
} from '../assets/globalStyles';
import useProgressiveImg from '../assets/progressiveImage';
import lowResBRImg from '../assets/images/lowResGymEquipBRImg.jpg';
import fullResBRImg from '../assets/images/highResGymEquipBRImg.jpg';

const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  /* Media Queries for skinny screen */
  @media screen only and (max-width: 600px) {
    width: 90vw;
  }
  @media screen only and (max-width: 400px) {
    width: 95vw;
  }
  @media screen only and (max-width: 300px) {
    width: 100vw;
  }
  background-color: white;
  border-radius: 10px;
  box-shadow: white;
  padding: 5px;
`;

const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 25px;
`;

//Title for the modal
const ModalTitle = styled.p`
  font-size: 2rem;
  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
  @media only screen and (max-width: 400px) {
    font-size: 1.2rem;
  }
  font-family: 'MontserratBold';
`;

//Text for the modal
const ModalText = styled.p`
  font-size: 1.5rem;
  @media only screen and (max-width: 600px) {
    font-size: 1.2rem;
  }
  @media only screen and (max-width: 400px) {
    font-size: 1rem;
  }
  font-family: 'Montserratlight';
  margin: 1rem;
`;

//Modal Component Wrapper
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 80%;
`;

const Table = styled.div`
  display: grid;
  width: 75vw;
  padding-left: 2vw;
  grid-auto-flow: row;
  align-items: center;
  grid-template-columns: repeat(3, auto);
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  grid-row-gap: 8px;
`;

//Workout Wrapper to hold each workout in a row
const ManageWorkoutsHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 10px;
`;

//Text to head the table
const ManageWorkoutsHeaderText = styled.p`
  font-size: 1.5rem;
  //Media Queries that reduce the font size for smaller screens
  @media only screen and (max-width: 600px) {
    font-size: 1.3rem;
  }
  @media only screen and (max-width: 400px) {
    font-size: 1.2rem;
  }

  font-family: 'MontserratBold';
  margin: 1rem;
  color: white;
`;

//Title of the page text
const PageTitle = styled.p`
  font-size: 2rem;
  font-family: 'MontserratBold';
  margin: 1rem;
  color: white;
  text-align: center;
`;

//Text for the page
const PageText = styled.p`
  font-size: 1.5rem;
  font-family: 'Montserratlight';
  margin: 1rem;
  color: white;
  text-align: center;
`;

//Component to share workout with other users
const ShareWorkout = (props) => {
  const [jsonString, setJsonString] = useState('');

  const handleInputChange = (event) => {
    setJsonString(event.target.value);
  };

  const handleJsonConversion = () => {
    try {
      const parsedJson = JSON.parse(jsonString);
      props.setWorkoutDataToEdit(parsedJson);
      props.openEditWorkout();
      props.closeModal();
    } catch (error) {
      console.error('Invalid JSON:', error);
      alert('Invalid JSON. Please check your input.');
    }
  };

  return (
    <ModalWrapper>
      <CloseIcon
        aria-label="close"
        onClick={props.closeModal}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: (theme) => theme.palette.grey[500],
          cursor: 'pointer',
          '&:hover': {
            color: (theme) => theme.palette.grey[700],
          },
        }}
      />
      <ModalTitle>Add a Shared Workout</ModalTitle>
      <ModalText>
        Here another user can send you one of the workouts they like, or you can
        send one of yours to them. To copy a workout, just click one of your
        workouts to edit and click the "share" button at the bottom. This will
        copy the workout to your clipboard, so you can paste it into an
        email/text, etc.
      </ModalText>
      <TextField
        style={{width: '90%'}}
        label="Raw Workout string/text"
        multiline
        rows={10}
        variant="outlined"
        value={jsonString}
        onChange={handleInputChange}
      />
      <div style={{height: '3rem'}} />
      <StyledButton
        fontSize={'1.5rem'}
        primary
        adaptiveSize
        onClick={handleJsonConversion}>
        Convert to Exercise
      </StyledButton>
    </ModalWrapper>
  );
};

//Background component for the workout modal using progressiveImg
const BlurredUpBackground = memo(({src, blur, cancelAnimate}) => {
  return (
    <BackgroundImageWrapper>
      <BackgroundImage src={src} blur={blur} cancelAnimate={cancelAnimate} />
    </BackgroundImageWrapper>
  );
});

const ManageWorkouts = (props) => {
  //Progressive image hook
  const [src, {blur, cancelAnimate}] = useProgressiveImg(
    lowResBRImg,
    fullResBRImg,
  );

  const authHook = useAuth();
  //Redirect to signin if props.authUser is null
  const redirect = useNavigate();

  useEffect(() => {
    if (!props.stillLoading) {
      if (props.authUser === null) {
        console.log('Should redirect to SIGN_IN');
        redirect(ROUTES.SIGN_IN);
      }
    }
  }, [props.authUser, props.stillLoading, redirect]);

  //State variable and functions to open and close the create workout modal
  const [createWorkoutOpen, setCreateWorkoutOpen] = useState(false);
  const openCreateWorkout = () => {
    setCreateWorkoutOpen(true);
  };
  const closeCreateWorkout = () => {
    setCreateWorkoutOpen(false);
  };

  //State variable and functions to open and close the edit workout modal
  const [editWorkoutOpen, setEditWorkoutOpen] = useState(false);
  const openEditWorkout = () => {
    setEditWorkoutOpen(true);
  };
  const closeEditWorkout = () => {
    setEditWorkoutOpen(false);
  };

  //State variable and functions to open and close the share workout modal
  const [shareWorkoutOpen, setShareWorkoutOpen] = useState(false);
  const openShareWorkout = () => {
    setShareWorkoutOpen(true);
  };
  const closeShareWorkout = () => {
    setShareWorkoutOpen(false);
  };

  //State variable and functions to open and close the delete workout modal
  const [deleteWorkoutOpen, setDeleteWorkoutOpen] = useState(false);
  const openDeleteWorkout = () => {
    setDeleteWorkoutOpen(true);
  };
  const closeDeleteWorkout = () => {
    setDeleteWorkoutOpen(false);
  };

  //State variable to hold the workout data to delete
  const [workoutToDelete, setWorkoutToDelete] = useState(undefined);

  //State to hold workout data
  const [workouts, setWorkouts] = useState([]);
  const [workoutDataToEdit, setWorkoutDataToEdit] = useState(undefined);

  //Fetch workout data from database
  useEffect(() => {
    if (props.authUser !== null) {
      //set a listener to get the workouts from the database
      const unsubscribe = onSnapshot(
        collection(authHook.db, 'users', props.authUser.uid, 'workouts'),
        (querySnapshot) => {
          const workoutList = querySnapshot.docs.map((doc) => doc.data());
          setWorkouts(workoutList);
          console.log('Workouts: ', workoutList);
        },
      );
      return () => {
        unsubscribe();
      };
    }
  }, [props.authUser, authHook.db]);

  //Function to delete a workout from the database called removeWorkout
  const removeWorkout = async (workoutName) => {
    //Delete the workout from the database
    const workoutRef = doc(
      authHook.db,
      'users',
      props.authUser.uid,
      'workouts',
      workoutName,
    );
    const response = await deleteDoc(workoutRef);
    console.log(response);
    //Close the modal
    closeDeleteWorkout();
  };

  const registerWorkoutInDB = async (workoutData) => {
    //Create a new workout in the database
    const workoutRef = doc(
      authHook.db,
      'users',
      props.authUser.uid,
      'workouts',
      workoutData.name,
    );
    const response = await setDoc(workoutRef, workoutData);
    console.log(response);
    //Close the modal
    closeCreateWorkout();
  };

  return (
    <PageWrapper>
      <BlurredUpBackground
        src={src}
        blur={blur}
        cancelAnimate={cancelAnimate}
      />
      <React.Fragment>
        <div style={{height: '3rem'}} />
        <PageTitle>Manage Workouts</PageTitle>
        {/* Modal component to create a new workout */}
        <Modal
          open={editWorkoutOpen}
          onClose={closeEditWorkout}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <ModalBox>
            <ModalInner>
              {workoutDataToEdit && (
                <AddOrChangeWorkout
                  buttonText={'Save Changes'}
                  authHook={authHook}
                  closeModal={closeEditWorkout}
                  dataIn={workoutDataToEdit}
                  title={'Edit a Wokout'}
                  registerWorkoutInDB={registerWorkoutInDB}
                  existingWorkouts={workouts}
                />
              )}
            </ModalInner>
          </ModalBox>
        </Modal>
        <Modal
          open={createWorkoutOpen}
          onClose={closeCreateWorkout}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <ModalBox>
            <ModalInner>
              <AddOrChangeWorkout
                buttonText={'Save Changes'}
                authHook={authHook}
                closeModal={closeCreateWorkout}
                dataIn={undefined}
                title={'Create A New Workout'}
                registerWorkoutInDB={registerWorkoutInDB}
                existingWorkouts={workouts}
              />
            </ModalInner>
          </ModalBox>
        </Modal>
        <Modal
          open={shareWorkoutOpen}
          onClose={closeShareWorkout}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <ModalBox>
            <ModalInner>
              <ShareWorkout
                setWorkoutDataToEdit={setWorkoutDataToEdit}
                openEditWorkout={openEditWorkout}
                authHook={authHook}
                closeModal={closeShareWorkout}
                registerWorkoutInDB={registerWorkoutInDB}
                existingWorkouts={workouts}
              />
            </ModalInner>
          </ModalBox>
        </Modal>
        {workoutToDelete && (
          <ConfirmAction
            dataForAction={workoutToDelete.name}
            callback={(data) => removeWorkout(data)}
            openState={deleteWorkoutOpen}
            handleCloseState={() => closeDeleteWorkout()}>
            Confirm Delete {workoutToDelete.name}
          </ConfirmAction>
        )}
        {workouts.length > 0 ? (
          <React.Fragment>
            <ManageWorkoutsHeaderWrapper>
              <StyledButton
                bright
                fontSize={'1.2rem'}
                adaptiveSize
                onClick={() => openCreateWorkout()}>
                New Workout
              </StyledButton>
              <StyledButton
                bright
                fontSize={'1.2rem'}
                adaptiveSize
                onClick={() => openShareWorkout()}>
                Import
              </StyledButton>
            </ManageWorkoutsHeaderWrapper>

            <Table>
              {['Workout Name', 'Edit', 'Delete'].map((item) => (
                <div>
                  <ManageWorkoutsHeaderText>{item}</ManageWorkoutsHeaderText>
                </div>
              ))}
              {workouts.map((workout) => (
                <React.Fragment key={workout.name}>
                  <div>
                    <PageText>{workout.name} Workout</PageText>
                  </div>
                  <div>
                    <EditIcon
                      style={{
                        cursor: 'pointer',
                        paddingRight: '2vw',
                        color: 'white',
                      }}
                      onClick={() => {
                        console.log(`Edit ${workout.name}`);
                        setWorkoutDataToEdit(workout);
                        openEditWorkout();
                      }}
                    />
                  </div>
                  <div>
                    <DeleteForeverIcon
                      style={{cursor: 'pointer', color: 'white'}}
                      onClick={() => {
                        setWorkoutToDelete({
                          name: workout.name,
                        });
                        openDeleteWorkout();
                      }}
                    />
                  </div>
                </React.Fragment>
              ))}
            </Table>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <PageText>You haven't made any workouts yet</PageText>
            <div style={{height: '3rem'}} />
            {/* Button to create a new workout, opens a modal */}
            <StyledButton
              bright
              adaptiveSize
              onClick={() => openCreateWorkout()}>
              Create A New Workout
            </StyledButton>
            <div style={{height: '5vh'}} />
            <StyledButton
              bright
              adaptiveSize
              onClick={() => openShareWorkout()}>
              Import A Workout
            </StyledButton>
          </React.Fragment>
        )}
      </React.Fragment>
    </PageWrapper>
  );
};

export default ManageWorkouts;
