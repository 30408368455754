import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import styled from '@emotion/styled';
import StyledButton from '../assets/buttons';
import {useAuth} from '../services/use-auth';
import {collection, onSnapshot} from 'firebase/firestore';
import WorkoutFlowComponent from './WorkoutFlow';
import lowResBRImg from '../assets/images/lowResPullUpBRImg.jpg';
import fullResBRImg from '../assets/images/highResPullUpBRImg.jpg';

import useProgressiveImg, {
  BlurredUpBackground,
} from '../assets/progressiveImage';

//Flexbox div to center all components
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
`;

//Title Text
const Title = styled.p`
  font-size: 2rem;
  font-family: 'MontserratBold';
  margin: 1rem;
  color: white;
  text-align: center;
`;

const WorkoutComponent = (props) => {
  const [src, {blur, cancelAnimate}] = useProgressiveImg(
    lowResBRImg,
    fullResBRImg,
  );
  //Redirect to signin if props.authUser is null
  const redirect = useNavigate();
  const authHook = useAuth();
  useEffect(() => {
    if (!props.stillLoading) {
      if (props.authUser === null) {
        console.log('Should redirect to SIGN_IN');
        redirect(ROUTES.SIGN_IN);
      }
    }
  }, [props.authUser, props.stillLoading, redirect]);

  const [currentWorkout, setCurrentWorkout] = useState(undefined);
  const [workouts, setWorkouts] = useState([]);

  useEffect(() => {
    if (props.authUser) {
      console.log('User is logged in, getting workouts');
      const unsub = onSnapshot(
        collection(authHook.db, 'users', props.authUser.uid, 'workouts'),
        (querySnapshot) => {
          const newWorkouts = [];
          querySnapshot.forEach((doc) => {
            newWorkouts.push({id: doc.id, ...doc.data()});
            console.log('Got a workout: ', doc.data());
          });
          setWorkouts(newWorkouts);
        },
      );
      return () => unsub();
    } else {
      console.log('No authUser');
    }
  }, [authHook.db, authHook.user]);

  return (
    <PageWrapper>
      <BlurredUpBackground
        src={src}
        blur={blur}
        cancelAnimate={cancelAnimate}
      />
      {!currentWorkout ? (
        <React.Fragment>
          <Title>Select a Workout</Title>
          {workouts.length > 0 ? (
            <React.Fragment>
              {workouts.map((workout) => (
                <React.Fragment key={workout.name}>
                  <div style={{height: '3vh'}} />
                  <StyledButton
                    key={workout.name.concat('button')}
                    fontSize={'1.5rem'}
                    bright
                    onClick={() =>
                      setCurrentWorkout({
                        name: workout.name,
                        intervals: workout.intervals,
                      })
                    }>
                    {workout.name}
                  </StyledButton>
                </React.Fragment>
              ))}
            </React.Fragment>
          ) : (
            <StyledButton
              adaptiveSize
              bright
              onClick={() => redirect(ROUTES.MANAGE_WORKOUTS)}>
              Create a Workout
            </StyledButton>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Title>Working Out</Title>
          <WorkoutFlowComponent workout={currentWorkout} />
        </React.Fragment>
      )}
    </PageWrapper>
  );
};

export default WorkoutComponent;
