import React, {useEffect, memo} from 'react';
import {useNavigate} from 'react-router-dom';
import * as ROUTES from './constants/routes';
import styled from '@emotion/styled';
import StyledButton from './assets/buttons';
import {
  BackgroundImage,
  BackgroundImageWrapper,
  PageWrapper,
} from './assets/globalStyles';
import useProgressiveImg from './assets/progressiveImage';
import lowResBRImg from './assets/images/lowResGymEquipBRImg.jpg';
import fullResBRImg from './assets/images/highResGymEquipBRImg.jpg';

// //Flexbox div to center all components
// const PageWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: space-evenly;
//   height: 100vh;
// `;

//Title of the page text
const PageTitle = styled.p`
  font-size: 2rem;
  font-family: 'MontserratBold';
  margin: 1rem;
  color: white;
  text-align: center;
`;

//Background component for the workout modal using progressiveImg
const BlurredUpBackground = memo(({src, blur, cancelAnimate}) => {
  return (
    <BackgroundImageWrapper>
      <BackgroundImage src={src} blur={blur} cancelAnimate={cancelAnimate} />
    </BackgroundImageWrapper>
  );
});

const Home = (props) => {
  const [src, {blur, cancelAnimate}] = useProgressiveImg(
    lowResBRImg,
    fullResBRImg,
  );

  //Redirect to signin if props.authUser is null
  const redirect = useNavigate();
  useEffect(() => {
    if (!props.stillLoading) {
      if (props.authUser === null) {
        console.log('Should redirect to SIGN_IN');
        redirect(ROUTES.SIGN_IN);
      }
    }
  }, [props.authUser, props.stillLoading, redirect]);

  return (
    <PageWrapper>
      <BlurredUpBackground
        src={src}
        blur={blur}
        cancelAnimate={cancelAnimate}
      />
      {props.stillLoading ? (
        <h1>Loading...</h1>
      ) : (
        <React.Fragment>
          <div style={{height: '5vh'}} />
          <PageTitle>Welcome {props.userData.username}</PageTitle>
          <div style={{height: '10vh'}} />
          <StyledButton
            bright
            fontSize={'1.5rem'}
            adaptiveSize
            onClick={() => redirect(ROUTES.MANAGE_WORKOUTS)}>
            Create or Edit Workouts
          </StyledButton>
          <div style={{height: '10vh'}} />
          <StyledButton
            bright
            fontSize={'1.5rem'}
            adaptiveSize
            onClick={() => redirect(ROUTES.WORKOUT)}>
            Let's Go!
          </StyledButton>
          {/* a div that is 25% view height at the bottom to space everything up */}
          <div style={{height: '25%'}} />
        </React.Fragment>
      )}
    </PageWrapper>
  );
};

export default Home;
