import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90vw;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 25vw;
  width: 50vw;
  height: 50vw;
  align-items: center;
  justify-content: center;
`;

const DisplayText = styled.h1`
  text-align: center;
  font-size: 1.8rem;
  font-family: 'MontserratBold';
  color: white;
`;

const CountdownTimer = (props) => {
  const circumference = 2 * Math.PI * 45;
  const [barOffset, setBarOffset] = useState(circumference);

  useEffect(() => {
    setBarOffset(
      circumference - (props.remainingTime / props.duration) * circumference,
    );
  }, [circumference, props.duration, props.remainingTime]);

  const barStyle = {
    strokeDasharray: circumference,
    strokeDashoffset: barOffset,
    strokeWidth: 8,
    stroke: 'url(#gradient)', // Reference the gradient by its ID
    fill: 'transparent',
    strokeLinecap: 'round',
    transition: 'stroke-dashoffset 50ms linear',
  };

  return (
    <React.Fragment>
      {props.remainingTime && (
        <ProgressWrapper>
          <BarWrapper>
            <svg viewBox="0 0 100 100" style={{width: '100%', height: 'auto'}}>
              <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#ee3189" />
                  <stop offset="100%" stopColor="#f36d21" />
                </linearGradient>
                <mask id="circleMask">
                  <rect width="100%" height="100%" fill="url(#gradient)" />
                </mask>
              </defs>
              <circle
                cx="50"
                cy="50"
                r="45"
                style={{
                  stroke: 'lightgray',
                  strokeWidth: 8,
                  fill: 'transparent',
                }}
              />
              <circle cx="50" cy="50" r="45" style={barStyle} />
            </svg>
          </BarWrapper>
          <TimeWrapper>
            <DisplayText>
              {props.display}
              <br /> {props.remainingTime} seconds
            </DisplayText>
          </TimeWrapper>
        </ProgressWrapper>
      )}
    </React.Fragment>
  );
  // const progress = 1 - props.remainingTime / props.duration;
  // const gradient = `conic-gradient(#ee3189 ${progress * 360}deg, #f36d21 0)`;

  // const barStyle = {
  //   strokeDasharray: circumference,
  //   strokeDashoffset: barOffset,
  //   strokeWidth: 8,
  //   stroke: gradient,
  //   fill: 'transparent',
  //   strokeLinecap: 'round',
  //   transition: 'stroke-dashoffset 50ms linear',
  // };

  // return (
  //   <React.Fragment>
  //     {props.remainingTime && (
  //       <ProgressWrapper>
  //         <BarWrapper>
  //           <svg viewBox="0 0 100 100" style={{width: '100%', height: 'auto'}}>
  //             <defs>
  //               <linearGradient
  //                 id="linearGradient"
  //                 x1="0%"
  //                 y1="0%"
  //                 x2="0%"
  //                 y2="100%">
  //                 <stop offset="0%" stopColor="#ee3189" />
  //                 <stop offset="100%" stopColor="#f36d21" />
  //               </linearGradient>
  //               <mask id="mask">
  //                 <rect x="0" y="0" width="100" height="100" fill="#fff" />
  //                 <circle cx="50" cy="50" r="45" fill="url(#linearGradient)" />
  //               </mask>
  //             </defs>
  //             <circle
  //               cx="50"
  //               cy="50"
  //               r="45"
  //               style={{
  //                 stroke: 'lightgray',
  //                 strokeWidth: 8,
  //                 fill: 'transparent',
  //               }}
  //             />
  //             <circle
  //               cx="50"
  //               cy="50"
  //               r="45"
  //               style={barStyle}
  //               mask="url(#mask)"
  //             />
  //           </svg>
  //         </BarWrapper>
  //         <TimeWrapper>
  //           <DisplayText>
  //             {props.display}
  //             <br /> {props.remainingTime} seconds
  //           </DisplayText>
  //         </TimeWrapper>
  //       </ProgressWrapper>
  //     )}
  //   </React.Fragment>
  // );
};

export default CountdownTimer;
