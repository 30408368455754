import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';

import MobileNav from './mobileNav';
import DesktopNav from './desktopNav';

import {ReactComponent as LoadingLogo} from '../assets/logos/animated-logo.svg';

const LoadingScreenDiv = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

const LoadingScreenOverlay = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: white;
  justify-content: center;
  align-items: center;
  z-index: 2;
  opacity: 0.45;
  backdrop-filter: blur(8px);
`;

const LoadingLogoStyled = styled(LoadingLogo)`
  z-index: 5;
  transform: translateY(-15vh);
  width: 20vw;
  @media only screen and (max-width: 800px) {
    width: 27vw;
  }
  @media only screen and (max-width: 650px) {
    width: 35vw;
  }
  @media only screen and (max-width: 450px) {
    width: 60vw;
  }
`;

const Nav = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const displayMobile = windowWidth >= 800 ? false : true;
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  return (
    <React.Fragment>
      {displayMobile ? (
        <MobileNav
          userData={props.userData}
          authUser={props.authUser}
          stillLoading={props.showLoading}
        />
      ) : (
        <DesktopNav userData={props.userData} authUser={props.authUser} />
      )}
      {props.showLoading && (
        <LoadingScreenDiv>
          <LoadingLogoStyled />
          <LoadingScreenOverlay />
        </LoadingScreenDiv>
      )}
    </React.Fragment>
  );
};

export default Nav;
