import React, {useState, useEffect} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {Tooltip} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import StyledButton from '../assets/buttons';

//Modal footer to hold buttons in a row
const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 20px;
`;

const SwitchText = styled.p`
  font-size: 1.3rem;
  font-family: 'Montserratlight';
  margin: 1rem;
  text-align: center;
`;

const TextButton = styled.p`
  font-family: 'MontserratLight';
  font-size: 1.4rem;
  font-style: strong;
  cursor: pointer;
`;

//Text Button for the interval title, with bold font
const IntervalTextButton = styled.p`
  font-family: 'MontserratBold';
  font-size: 1.6rem;
  font-style: strong;
  cursor: pointer;
`;

//Modal Component Wrapper
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 80vh;
  width: 80vw;
  @media screen only and (max-width: 600px) {
    width: 90vw;
  }
  @media screen only and (max-width: 400px) {
    width: 95vw;
  }
  @media screen only and (max-width: 300px) {
    width: 100vw;
  }
`;

//Horizontal div to hold each exercise in the interval with flex start
const HorizontalDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

//Exercise Number Text to display the number of the exercise in the interval, with a gradient background
const ExerciseNumberText = styled.p`
  font-family: 'MontserratBold';
  font-size: 1.8rem;
  font-style: strong;
  background: linear-gradient(90deg, #f36d21, #ee3189 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 1rem;
`;

//Styled Text Field which adapts to the width of the parent div
const TitleTextField = styled(TextField)`
  width: 550px;
  @media (max-width: 600px) {
    width: 350px;
  }
  @media (max-width: 400px) {
    width: 250px;
  }
  @media (max-width: 300px) {
    width: 200px;
  }
  font-size: 26px;
  margin-top: 0.2rem;
`;

//Styled Text Field which is indented to the right
const IntervalTextField = styled(TextField)`
  width: 450px;
  font-size: 22px;
  @media (max-width: 600px) {
    width: 350px;
    font-size: 18px;
  }
  @media (max-width: 400px) {
    width: 250px;
    font-size: 16px;
  }
  @media (max-width: 300px) {
    width: 200px;
    font-size: 12px;
  }
  margin-top: 0.2rem;
  margin-left: 1rem;
`;

//Exercise Text Field which adapts to the width of the parent div
const ExerciseTextField = styled(TextField)`
  width: 400px;
  font-size: 22px;
  @media (max-width: 600px) {
    width: 350px;
    font-size: 18px;
  }
  @media (max-width: 400px) {
    width: 250px;
    font-size: 16px;
  }
  @media (max-width: 300px) {
    width: 200px;
    font-size: 12px;
  }
  margin-top: 0.2rem;
  margin-left: 4rem;
`;

//Box to hold the entire form and resize it to fit the screen
const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 2;
  padding-bottom: 3;
  padding-left: 2;
  padding-right: 2;
`;

const AddOrChangeWorkout = (props) => {
  // State variable to hold workout form data
  const [values, setValues] = useState({
    workoutName: '',
    workoutDescription: '',
  });

  // State variable to hold intervals
  const [intervals, setIntervals] = useState([]);

  const [currentFocusedExerciseName, setCurrentFocusedExerciseName] =
    useState('');

  const [currentFocusedIntervalName, setCurrentFocusedIntervalName] =
    useState('');

  const handleChange = (event) => {
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleAddInterval = () => {
    setIntervals([
      ...intervals,
      {intervalName: '', sets: '', exercises: [], completeData: false},
    ]);
  };

  //Remove an interval from the workout
  const handleRemoveInterval = (intervalIndex) => {
    const newIntervals = intervals.filter((interval, index) => {
      return index !== intervalIndex;
    });
    setIntervals(newIntervals);
  };

  //Add an exercise to the interval
  const handleAddExercise = (intervalIndex) => {
    const newIntervals = intervals.map((interval, index) => {
      if (index === intervalIndex) {
        return {
          ...interval,
          exercises: [
            ...interval.exercises,
            {
              exerciseName: '',
              quantity: '',
              restTime: '',
              type: 'reps',
              completeData: false,
            },
          ],
        };
      }
      return interval;
    });
    setIntervals(newIntervals);
  };

  //Remove an exercise from the interval
  const handleRemoveExercise = (intervalIndex, exerciseIndex) => {
    const newIntervals = intervals.map((interval, index) => {
      if (index === intervalIndex) {
        return {
          ...interval,
          exercises: interval.exercises.filter(
            (exercise, i) => i !== exerciseIndex,
          ),
        };
      }
      return interval;
    });
    setIntervals(newIntervals);
  };

  const handleIntervalChange = (intervalIndex, field, value) => {
    const newIntervals = intervals.map((interval, index) =>
      index === intervalIndex ? {...interval, [field]: value} : interval,
    );
    setIntervals(newIntervals);
  };

  const handleExerciseChange = (intervalIndex, exerciseIndex, field, value) => {
    const newIntervals = intervals.map((interval, i) => {
      if (i === intervalIndex) {
        return {
          ...interval,
          exercises: interval.exercises.map((exercise, j) =>
            j === exerciseIndex
              ? {...exercise, [field]: field === 'type' ? value : value}
              : exercise,
          ),
        };
      }
      return interval;
    });
    setIntervals(newIntervals);
  };

  useEffect(() => {
    if (props.dataIn !== undefined) {
      //Log the props.dataIn which should be in the form of a workout object
      console.log(props.dataIn);

      // Build intervals from props.dataIn
      const newIntervals = props.dataIn.intervals.map((interval) => {
        return {
          intervalName: interval.intervalName,
          sets: interval.sets,
          completeData: true,
          exercises: interval.exercises.map((exercise) => {
            return {
              exerciseName: exercise.exerciseName,
              quantity: exercise.quantity,
              restTime: exercise.restTime,
              type: exercise.type,
              completeData: true,
            };
          }),
        };
      });
      setIntervals(newIntervals);
      setValues({
        name: props.dataIn.name,
        workoutDescription: props.dataIn.description,
      });
    }
  }, [props.dataIn]);

  const registerWorkout = () => {
    console.log('registering workout');
    const workout = {
      name: values.name,
      intervals: intervals,
    };
    props.registerWorkoutInDB(workout);
    props.closeModal();
  };

  //function to export workout to a JSON string
  const exportWorkout = () => {
    const workout = {
      name: values.name,
      intervals: intervals,
    };
    console.log(workout);
    const workoutString = JSON.stringify(workout);
    console.log(workoutString);
    navigator.clipboard.writeText(workoutString);
    props.closeModal();
  };

  //Check if all fileds in the exercies are filled when loosing focus
  const checkExerciseFields = (intervalIndex, exerciseIndex) => {
    const exercise = intervals[intervalIndex].exercises[exerciseIndex];
    console.log("Checking exercise's fields", intervalIndex, exerciseIndex);
    console.log(exercise.exerciseName, exercise.quantity, exercise.restTime);
    if (
      exercise.exerciseName === '' ||
      exercise.quantity === '' ||
      exercise.restTime === ''
    ) {
      //Log the exercise that is missing fields
      console.log("The exercise isn't completely filled:", exercise);
      //Change exercise prop completeData to false if not currently focused
      // handleExerciseChange(intervalIndex, exerciseIndex, 'completeData', false);
    } else if (
      exercise.exerciseName.length > 0 &&
      exercise.quantity > 0 &&
      exercise.restTime > 0
    ) {
      //Log the exercise that is completely filled
      console.log('The exercise is completely filled:', exercise);
      //Change the exercise prop completeData to true if all fields are filled and not currently focused
      if (
        (exercise.completeData === undefined ||
          exercise.completeData === false) &&
        `interval-${intervalIndex}-exercise-${exerciseIndex}` !==
          currentFocusedExerciseName
      ) {
        //Log the exercise that is completely filled
        console.log('Changing value to true');
        //Change exercise prop completeData to true
        handleExerciseChange(
          intervalIndex,
          exerciseIndex,
          'completeData',
          true,
        );
      }
    }
  };

  //Iterate through entire workout and check if all exercises are filled using checkExerciseFields
  const checkWorkoutFields = () => {
    console.log('Checking workout fields');
    intervals.forEach((interval, intervalIndex) => {
      interval.exercises.forEach((_exercise, exerciseIndex) => {
        checkExerciseFields(intervalIndex, exerciseIndex);
      });
    });
  };

  //Iterate through intervals and check if all interval fields are filled
  const checkIntervalFields = () => {
    console.log('Checking interval fields');
    intervals.forEach((interval, intervalIndex) => {
      if (
        interval.intervalName === '' ||
        interval.sets === '' ||
        interval.exercises.length === 0
      ) {
        console.log('Interval is not completely filled');
        //Change interval prop completeData to false if not currently focused
        // handleIntervalChange(intervalIndex, 'completeData', false);
      } else if (
        interval.intervalName.length > 0 &&
        interval.sets > 0 &&
        interval.exercises.length > 0
      ) {
        console.log('Interval is completely filled');
        //Change interval prop completeData to true if all fields are filled and not currently focused
        if (
          (interval.completeData === undefined ||
            interval.completeData === false) &&
          `interval-${intervalIndex}` !== currentFocusedIntervalName
        ) {
          console.log('Changing value to true');
          handleIntervalChange(intervalIndex, 'completeData', true);
        }
      }
    });
  };

  //useEffect to check all workout fields when the state of currentFocusedExerciseName changes
  useEffect(() => {
    checkWorkoutFields();
  }, [currentFocusedExerciseName]);

  //useEffect to check all interval fields when the state of currentFocusedIntervalName changes
  useEffect(() => {
    checkIntervalFields();
  }, [currentFocusedIntervalName]);

  return (
    <ModalWrapper>
      <h2>{props.title}</h2>
      {/* Close Modal icon button */}
      <CloseIcon
        aria-label="close"
        onClick={props.closeModal}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          //media query to change the size of the icon button depending on the screen size
          '@media (max-width: 600px)': {
            top: '1rem',
            right: '5rem',
          },
          color: (theme) => theme.palette.grey[500],
          cursor: 'pointer',
          '&:hover': {
            color: (theme) => theme.palette.grey[700],
          },
        }}
      />
      <FormBox component="form" noValidate autoComplete="off">
        <Paper
          elevation={0}
          style={{
            width: '100%',
            maxHeight: '65vh', // Adjust the height as needed
            overflowY: 'auto',
            padding: 2,
            paddingTop: '1rem',
          }}>
          <TitleTextField
            label="Workout Name"
            name="name"
            variant="outlined"
            color="secondary"
            value={values['name']}
            onChange={handleChange}
          />
          {intervals.map((interval, intervalIndex) => (
            <div style={{marginLeft: '3rem'}} key={`interval-${intervalIndex}`}>
              {/* Tooltip to explain that this removes the interval */}
              <Tooltip title={`Remove Interval ${interval.intervalName}`}>
                <CloseIcon
                  style={{
                    cursor: 'pointer',
                    marginLeft: '75%',
                    color: (theme) => theme.palette.grey[500],
                    '&:hover': {
                      color: (theme) => theme.palette.grey[700],
                    },
                  }}
                  onClick={() => handleRemoveInterval(intervalIndex)}
                />
              </Tooltip>
              <React.Fragment>
                {interval.completeData ? (
                  <IntervalTextButton
                    onClick={() =>
                      handleIntervalChange(intervalIndex, 'completeData', false)
                    }>
                    {interval.intervalName} for {interval.sets} sets
                  </IntervalTextButton>
                ) : (
                  <React.Fragment>
                    <IntervalTextField
                      label={`Interval ${intervalIndex + 1} Name`}
                      name={`intervalName${intervalIndex}`}
                      variant="outlined"
                      color="secondary"
                      value={interval.intervalName}
                      onChange={(e) =>
                        handleIntervalChange(
                          intervalIndex,
                          'intervalName',
                          e.target.value,
                        )
                      }
                      onFocus={() => {
                        setCurrentFocusedIntervalName(
                          `interval-${intervalIndex}`,
                        );
                        setCurrentFocusedExerciseName('');
                      }}
                    />
                    <IntervalTextField
                      label={`Interval ${intervalIndex + 1} Sets`}
                      name={`intervalSets${intervalIndex}`}
                      type="number"
                      variant="outlined"
                      color="secondary"
                      value={interval.sets}
                      onChange={(e) =>
                        handleIntervalChange(
                          intervalIndex,
                          'sets',
                          e.target.value,
                        )
                      }
                      onFocus={() => {
                        setCurrentFocusedIntervalName(
                          `interval-${intervalIndex}`,
                        );
                        setCurrentFocusedExerciseName('');
                      }}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>

              {interval.exercises.map((exercise, exerciseIndex) => (
                <React.Fragment>
                  {exercise.completeData ? (
                    <HorizontalDiv>
                      <ExerciseNumberText>
                        {exerciseIndex + 1}.
                      </ExerciseNumberText>
                      <TextButton
                        onClick={() =>
                          handleExerciseChange(
                            intervalIndex,
                            exerciseIndex,
                            'completeData',
                            false,
                          )
                        }>
                        {exercise.exerciseName} for {exercise.quantity}{' '}
                        {exercise.type === 'time' ? 'seconds' : 'reps'}, then{' '}
                        {exercise.restTime} second rest
                      </TextButton>
                    </HorizontalDiv>
                  ) : (
                    <React.Fragment
                      key={`interval-${intervalIndex}-exercise-${exerciseIndex}`}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'flex-end',
                          width: '100%',
                        }}>
                        <Tooltip
                          title={`Remove Exercise ${exercise.exerciseName}`}>
                          <CloseIcon
                            style={{
                              cursor: 'pointer',
                              marginLeft: '85%',
                              color: (theme) => theme.palette.grey[500],
                              '&:hover': {
                                color: (theme) => theme.palette.grey[700],
                              },
                            }}
                            onClick={(e) =>
                              handleRemoveExercise(intervalIndex, exerciseIndex)
                            }
                          />
                        </Tooltip>
                      </div>
                      <ExerciseTextField
                        label={`Exercise ${exerciseIndex + 1} Name`}
                        name={`exerciseName${exerciseIndex}`}
                        variant="outlined"
                        color="secondary"
                        value={exercise.exerciseName}
                        onChange={(e) =>
                          handleExerciseChange(
                            intervalIndex,
                            exerciseIndex,
                            'exerciseName',
                            e.target.value,
                          )
                        }
                        onFocus={(e) => {
                          console.log('focused', exercise.exerciseName);
                          setCurrentFocusedExerciseName(
                            `interval-${intervalIndex}-exercise-${exerciseIndex}`,
                          );
                          setCurrentFocusedIntervalName('');
                        }}
                      />
                      <FormControlLabel
                        //Styles to vertically center the switch and label
                        control={
                          <Switch
                            sx={{ml: 10, mt: 2}}
                            name={`exerciseType${exerciseIndex}`}
                            checked={exercise.type === 'time'}
                            onChange={(e) =>
                              handleExerciseChange(
                                intervalIndex,
                                exerciseIndex,
                                'type',
                                e.target.checked ? 'time' : 'reps',
                              )
                            }
                            onFocus={(e) => {
                              setCurrentFocusedExerciseName(
                                `interval-${intervalIndex}-exercise-${exerciseIndex}`,
                              );
                              setCurrentFocusedIntervalName('');
                            }}
                            color="secondary"
                          />
                        }
                        label={
                          <SwitchText>
                            {exercise.type === 'time'
                              ? 'Timed Exercise'
                              : 'Reps Exercise'}
                          </SwitchText>
                        }
                      />
                      <ExerciseTextField
                        label={`Exercise ${exerciseIndex + 1} ${
                          exercise.type === 'reps' ? 'Reps' : 'Time'
                        }`}
                        name={`exerciseReps${exerciseIndex}`}
                        variant="outlined"
                        color="secondary"
                        value={exercise.quantity}
                        type="number"
                        InputProps={{
                          endAdornment:
                            exercise.type === 'time' ? (
                              <InputAdornment position="end">
                                seconds
                              </InputAdornment>
                            ) : null,
                        }}
                        onChange={(e) =>
                          handleExerciseChange(
                            intervalIndex,
                            exerciseIndex,
                            'quantity',
                            e.target.value,
                          )
                        }
                        onFocus={(e) => {
                          setCurrentFocusedExerciseName(
                            `interval-${intervalIndex}-exercise-${exerciseIndex}`,
                          );
                          setCurrentFocusedIntervalName('');
                        }}
                      />
                      <ExerciseTextField
                        label={`Exercise ${exerciseIndex + 1} Rest Time`}
                        name={`exerciseRestTime${exerciseIndex}`}
                        type="number"
                        variant="outlined"
                        color="secondary"
                        value={exercise.restTime}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              seconds
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) =>
                          handleExerciseChange(
                            intervalIndex,
                            exerciseIndex,
                            'restTime',
                            e.target.value,
                          )
                        }
                        onFocus={(e) => {
                          setCurrentFocusedExerciseName(
                            `interval-${intervalIndex}-exercise-${exerciseIndex}`,
                          );
                          setCurrentFocusedIntervalName('');
                        }}
                      />
                      <br />
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
              <div style={{height: '7px'}} />
              <StyledButton
                primary
                fontSize={'1.2rem'}
                adaptiveSize
                onClick={(event) => {
                  event.preventDefault();
                  handleAddExercise(intervalIndex);
                  setCurrentFocusedIntervalName('');
                }}>
                Add Exercise
              </StyledButton>
            </div>
          ))}

          <div style={{height: '7px'}} />
          <StyledButton
            primary
            fontSize={'1.2rem'}
            adaptiveSize
            onClick={(e) => {
              e.preventDefault();
              handleAddInterval();
              setCurrentFocusedExerciseName('');
            }}>
            Add Interval
          </StyledButton>
        </Paper>
        <ModalFooter>
          <StyledButton
            primary
            fontSize={'1.4rem'}
            adaptiveSize
            onClick={(e) => {
              e.preventDefault();
              registerWorkout();
            }}>
            {props.buttonText}
          </StyledButton>
          {props.dataIn && (
            <StyledButton
              primary
              fontSize={'1.4rem'}
              adaptiveSize
              onClick={(e) => {
                e.preventDefault();
                exportWorkout();
              }}>
              Workout to Text
            </StyledButton>
          )}
        </ModalFooter>
      </FormBox>
    </ModalWrapper>
  );
};

export default AddOrChangeWorkout;
