import React from 'react';
import styled from '@emotion/styled';
import {keyframes, css} from '@emotion/react';

import {fadeInUp} from 'react-animations';

const fadeUpAnimation = keyframes`${fadeInUp}`;

const themedStyles = (props) => {
  if (props.primary) {
    return css`
      font-size: ${props.fontSize};

      &:hover {
        transition: 0.3s ease-in-out;
        background-image: linear-gradient(to right, #ee3189, #f36d21);
      }
    `;
  } else if (props.secondary) {
    return css`
      font-size: ${props.fontSize};

      &:hover {
        transition: 0.3s ease-in-out;
        background-image: linear-gradient(to right, #469dd7, #70c8b8);
      }
    `;
  } else if (props.tertiary) {
    return css`
      font-size: ${props.fontSize};

      &:hover {
        transition: 0.3s ease-in-out;
        background-image: linear-gradient(180deg, #454545, black);
      }
    `;
  } else if (props.bright) {
    return css`
      font-size: ${({fontSize}) => fontSize};
      color: black;
      background-image: linear-gradient(to right, white, #dddddd);
      &:hover {
        color: white;
        background-image: linear-gradient(to right, #ee3189, #f36d21);
        transition: all 0.3s ease-in-out;
      }
    `;
  } else {
    return css``;
  }
};

const adaptiveSize = (props) => {
  if (props.adaptiveSize) {
    return css`
      font-size: 1.9rem;
      @media (max-width: 1024px) {
        font-size: 1.7rem;
      }
      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
      @media (max-width: 425px) {
        font-size: 1.2rem;
      }
      @media (max-width: 375px) {
        font-size: 1rem;
      }
    `;
  } else {
    return css``;
  }
};

const InitialStyledButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0.4rem 1.5rem;
  font-size: 1.8rem;
  font-family: 'MontserratSemiBold';
  color: white;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #1e242b, black);
  border-radius: 0.6rem;
  width: ${({width}) => width};
  height: ${({height}) => height};
  margin: ${({margin}) => margin};
  margin-left: ${({marginLeft}) => marginLeft};
  cursor: pointer;

  ${themedStyles}

  ${({small}) =>
    small &&
    css`
      padding: 0.1rem 0.4rem;
    `}
    
${adaptiveSize}

  ${({slideUp}) =>
    slideUp &&
    css`
      animation: 1s ${fadeUpAnimation};
    `}
`;
//#4f5354, #211f21
const StyledButton = ({
  primary,
  secondary,
  tertiary,
  bright,
  slideUp,
  fontSize,
  width,
  height,
  children,
  disabled,
  type,
  margin,
  onClick,
  ml,
  adaptiveSize,
}) => {
  return (
    <InitialStyledButton
      disabled={disabled}
      type={type}
      onClick={onClick}
      primary={primary}
      secondary={secondary}
      tertiary={tertiary}
      bright={bright}
      slideUp={slideUp}
      fontSize={fontSize}
      width={width}
      height={height}
      margin={margin}
      marginLeft={ml}
      adaptiveSize={adaptiveSize}>
      {children}
    </InitialStyledButton>
  );
};

export default StyledButton;
