import React from 'react';
import styled from '@emotion/styled';

const StyledBurger = styled.button`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 0.8rem;
  margin-right: 3vw;
  width: 4rem;
  height: 4rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 5;

  &:focus {
    outline: none;
  }

  div {
    height: 0.38rem;
    background: ${({open}) => (open ? 'white' : 'black')};
    border-radius: 10px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    transform-origin: 1px;

    :first-child {
      width: 3.4rem;
      opacity: ${({open}) => (open ? '0' : '1')};
      transform: ${({open}) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(2) {
      width: 4rem;
      transform: ${({open}) =>
        open ? 'rotate(45deg) translateY(-7px)' : 'rotate(0)'};
    }

    :nth-child(3) {
      width: 3.4rem;
      opacity: ${({open}) => (open ? '0' : '1')};
      transform: ${({open}) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(4) {
      width: 4rem;
      transform: ${({open}) =>
        open ? 'rotate(-45deg) translateY(7px)' : 'rotate(0)'};
    }
  }
`;

const Burger = ({open, setOpen, stillLoading}) => {
  //   const [open, setOpen] = useState(false);
  return (
    <StyledBurger
      open={open}
      onClick={() => {
        if (!stillLoading) {
          setOpen(!open);
        }
      }}>
      <div />
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default Burger;
