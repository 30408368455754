import {createGlobalStyle} from 'styled-components';

import MontserratRegular from './Montserrat-Regular.woff';
import MontserratRegular2 from './Montserrat-Regular.woff2';

import MontserratBold from './Montserrat-Bold.woff';
import MontserratBold2 from './Montserrat-Bold.woff2';

import MontserratMedium from './Montserrat-Medium.woff';
import MontserratMedium2 from './Montserrat-Medium.woff2';

import MontserratSemiBold from './Montserrat-SemiBold.woff';
import MontserratSemiBold2 from './Montserrat-SemiBold.woff2';

import MontserratLight from './Montserrat-Light.woff';
import MontserratLight2 from './Montserrat-Light.woff2';

export default createGlobalStyle`
@font-face {
    font-family: 'MontserratReg';
    src: url(${MontserratRegular2}) format('woff2'),
    url(${MontserratRegular}) format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'MontserratBold';
    src: url(${MontserratBold2}) format('woff2'),
    url(${MontserratBold}) format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'MontserratMed';
    src: url(${MontserratMedium2}) format('woff2'),
    url(${MontserratMedium}) format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'MontserratSemiBold';
    src: url(${MontserratSemiBold2}) format('woff2'),
    url(${MontserratSemiBold}) format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'MontserratLight';
    src: url(${MontserratLight2}) format('woff2'),
    url(${MontserratLight}) format('woff');
    font-weight: 100;
    font-style: normal;
}
`;
