import React, {useState, useEffect, memo} from 'react';
import {useAuth} from '../services/use-auth';
import {updatePassword} from '@firebase/auth';
import {useNavigate} from 'react-router-dom';
import * as ROUTES from '../constants/routes';

import styled from '@emotion/styled';
import {Box, TextField} from '@mui/material';
import StyledButton from '../assets/buttons';

import useProgressiveImg from '../assets/progressiveImage';
import lowResBRImg from '../assets/images/lowResLoginBackground.jpg';
import fullResBRImg from '../assets/images/fullResLoginBackground.jpg';
import {BackgroundImage, BackgroundImageWrapper} from '../assets/globalStyles';

const AccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.92);
  padding: 20px;
  border-radius: 8px;
  opacity: 0.85;
`;

const TextButton = styled.p`
  font-family: 'MontserratMed';
  font-size: 18px;
  font-style: strong;
  cursor: pointer;
`;

const Title = styled.p`
  font-size: 26px;
  font-family: 'MontserratMed';
`;

//Element Wrapper for the text fields
const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const PageWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15vh;
`;

const BlurredUpBackground = memo(({src, blur, cancelAnimate}) => {
  return (
    <BackgroundImageWrapper>
      <div style={{height: '15vh'}} />
      <BackgroundImage blur={blur} cancelAnimate={cancelAnimate} src={src} />
    </BackgroundImageWrapper>
  );
});

const AccountPage = (props) => {
  const [src, {blur, cancelAnimate}] = useProgressiveImg(
    lowResBRImg,
    fullResBRImg,
  );

  const authHook = useAuth();

  //Redirect to sign in page if user is not signed in
  const redirect = useNavigate();
  useEffect(() => {
    if (!props.stillLoading) {
      if (props.authUser === null) {
        console.log('Should redirect to SIGN_IN');
        redirect(ROUTES.SIGN_IN);
      }
    }
  }, [props.authUser, props.stillLoading, redirect]);

  const [formInputsChangePassword, setFormInputsChangePassword] = useState({
    password: '',
    passwordConfirm: '',
  });

  const [entryErrors, setEntryErrors] = useState({
    password: false,
    passwordConfirm: false,
  });
  const [entryFeedback, setEntryFeedback] = useState({
    password: null,
    passwordConfirm: null,
  });

  const handleChange = async (event) => {
    await setFormInputsChangePassword((formInputsChangePassword) => ({
      ...formInputsChangePassword,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    if (entryErrors.password === true) {
      validatePassword();
    }
    if (entryErrors.passwordConfirm === true) {
      validatePasswordConfirm();
    }
  }, [formInputsChangePassword]);

  const validatePassword = () => {
    if (formInputsChangePassword.password === '') {
      setEntryErrors((entryErrors) => ({...entryErrors, password: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        password: 'You must enter a password',
      }));
    } else {
      const longerThan8 = formInputsChangePassword.password.length > 8;
      if (!longerThan8) {
        setEntryErrors((entryErrors) => ({...entryErrors, password: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          password: 'Passwords must be longer than 8 characters',
        }));
      } else {
        setEntryErrors((entryErrors) => ({...entryErrors, password: false}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          password: null,
        }));
      }
    }
  };

  const validatePasswordConfirm = () => {
    if (formInputsChangePassword.passwordConfirm === '') {
      setEntryErrors((entryErrors) => ({
        ...entryErrors,
        passwordConfirm: true,
      }));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        passwordConfirm: 'You must enter a password',
      }));
    } else {
      const match =
        formInputsChangePassword.password ===
        formInputsChangePassword.passwordConfirm;
      if (!match) {
        setEntryErrors((entryErrors) => ({
          ...entryErrors,
          passwordConfirm: true,
        }));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          passwordConfirm: 'Your passwords do not match',
        }));
      } else {
        setEntryErrors((entryErrors) => ({
          ...entryErrors,
          passwordConfirm: false,
        }));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          passwordConfirm: null,
        }));
      }
    }
  };

  const submitChangePassword = () => {
    console.log(formInputsChangePassword);
    const user = authHook.auth.currentUser;
    const newPassword = formInputsChangePassword.password;

    updatePassword(user, newPassword)
      .then(() => {
        console.log(`Password succesfully updated.`);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  return (
    <PageWrapper>
      <BlurredUpBackground
        src={src}
        blur={blur}
        cancelAnimate={cancelAnimate}
      />
      <AccountWrapper>
        <Title>Manage {props.userData.username}'s Account</Title>
        {/* Change Password Form */}
        <ElementWrapper>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              pb: 3,
              pl: 2,
              pr: 2,
            }}
            noValidate
            autoComplete="off">
            <Title>Change Password</Title>
            <TextField
              sx={{width: '350px', fontSize: '22px'}}
              label="New Password"
              name="password"
              type="password"
              variant="outlined"
              color="secondary"
              value={formInputsChangePassword['password']}
              onChange={handleChange}
              onBlur={() => validatePassword()}
              error={entryErrors.password}
              helperText={entryFeedback.password}
            />
            <TextField
              sx={{width: '350px', fontSize: '22px'}}
              label="Confirm New Password"
              name="passwordConfirm"
              type="password"
              variant="outlined"
              color="secondary"
              value={formInputsChangePassword['passwordConfirm']}
              onChange={handleChange}
              onBlur={() => validatePasswordConfirm()}
              error={entryErrors.passwordConfirm}
              helperText={entryFeedback.passwordConfirm}
            />
            <StyledButton
              disabled={Object.values(entryErrors).some((err) => err)}
              primary
              onClick={(event) => {
                event.preventDefault();
                submitChangePassword();
              }}
              width="350px"
              fontSize="26px">
              Change Password
            </StyledButton>
          </Box>
        </ElementWrapper>
      </AccountWrapper>
    </PageWrapper>
  );
};

export default AccountPage;
