import React, {useState} from 'react';
import styled from '@emotion/styled';
import {Link, NavLink} from 'react-router-dom';
import SignOutModal from './signOut';
import * as ROUTES from '../constants/routes';
import * as ROLES from '../constants/roles';

import LogoDesktop from '../assets/logos/LogoNoName.svg';
import LogoLogin from '../assets/logos/loginSimple.svg';
import LogoLogoutSimple from '../assets/logos/logoutSimple.svg';

const Logo = styled.img`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 8px;
  height: 5vh;
`;

const LogoText = styled.p`
  display: flex;
  flex-direction: row;
  font-size: 2.1rem;
  font-family: 'MontserratSemiBold';
  color: black;
`;

const LoginLogo = styled.img`
  height: 40px;
  margin: 10px;
  cursor: pointer;
`;

const LogoLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-width: 50%;
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 8vh;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
`;

const LogoutLogo = styled.img`
  height: 40px;
  margin: 10px;
  cursor: pointer;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'MontserratSemiBold', 'Helvetica Neue';
  color: black;
  font-size: 28px;
  padding-left: 10px;
  padding-right: 15px;
  text-decoration: none;
  @media only screen and (max-width: 1050px) {
    font-size: 25px;
  }
  &:after {
    content: '';
    height: 2px;
    background-image: linear-gradient(
      to right,
      #ee3189,
      #f36d21,
      #70c8b8,
      #469dd7
    );
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

  &:hover:after {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  &.active:after {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  &:hover {
    text-decoration: none;
    color: #1d242c;
  }
`;

const DesktopNav = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <NavWrapper>
      <LogoWrapper>
        {/* <picture>
          <source media="(min-width: 600px)" srcSet={LogoDesktop} />
          <source media="(max-width: 599px)" srcSet={LogoMobile} />
        </picture> */}
        <LogoLink to={ROUTES.HOME}>
          <Logo src={LogoDesktop} />
          <LogoText>Rhythmiq.</LogoText>
        </LogoLink>
      </LogoWrapper>
      {/* MIDDLE POINT */}
      <MenuWrapper>
        {props.authUser &&
          props.userData &&
          props.userData.roles &&
          props.userData.roles[ROLES.ADMIN] && (
            <StyledNavLink to={ROUTES.ADMIN}>ADMIN</StyledNavLink>
          )}
        {props.authUser && (
          <StyledNavLink to={ROUTES.MANAGE_WORKOUTS}>MANAGE</StyledNavLink>
        )}
        {props.authUser && (
          <StyledNavLink to={ROUTES.WORKOUT}>WORKOUT!</StyledNavLink>
        )}
        {props.authUser && (
          <StyledNavLink to={ROUTES.ACCOUNT}>ACCOUNT</StyledNavLink>
        )}
        {props.authUser ? (
          <React.Fragment>
            <LogoutLogo src={LogoLogoutSimple} onClick={() => handleOpen()} />
            <SignOutModal
              open={open}
              handleClose={handleClose}
              userData={props.userData}
            />
          </React.Fragment>
        ) : (
          //   <LoginLogo src={LogoLogout} onClick={() => signOutFunc()} />
          <Link to={ROUTES.SIGN_IN}>
            <LoginLogo src={LogoLogin} />
          </Link>
        )}
      </MenuWrapper>
    </NavWrapper>
  );
};

export default DesktopNav;
