import React, {useState, useEffect} from 'react';
import CountdownTimer from '../assets/CountdownTimer';
import StyledButton from '../assets/buttons';
import styled from '@emotion/styled';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

const RepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60%;
`;

//PageWrapper is a flexbox div to center all components with flex start
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`;

const IntervalTitle = styled.p`
  font-size: 1.5rem;
  font-family: 'MontserratBold';
  color: white;
`;

const ExerciseTitle = styled.p`
  font-size: 2.2rem;
  font-family: 'MontserratBold';
  text-align: center;
  color: white;
`;

const RepsCounterText = styled.p`
  font-size: 2.5rem;
  font-family: 'Montserratlight';
  color: white;
`;

const NextExerciseText = styled.p`
  font-size: 1.5rem;
  font-family: 'Montserratlight';
  text-align: center;
  color: white;
`;

const WorkoutFlowComponent = (props) => {
  const [currentInterval, setCurrentInterval] = useState(0);
  const [currentSet, setCurrentSet] = useState(0);
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
  const [currentExercise, setCurrentExercise] = useState(null);
  const [nextExercise, setNextExercise] = useState(null);
  const [timer, setTimer] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const [complete, setComplete] = useState(false);
  const [totalExercises, setTotalExercises] = useState(0);
  const [exercisesComplete, setExercisesComplete] = useState(0);
  const [workoutProgress, setWorkoutProgress] = useState(0);

  useEffect(() => {
    //Function to count the total number of exercises in the workout
    const countExercises = () => {
      let totalExercises = 0;
      props.workout.intervals.forEach((interval) => {
        totalExercises += interval.exercises.length * interval.sets;
      });
      console.log(totalExercises);
      return totalExercises;
    };
    setTotalExercises(countExercises());
  }, [props.workout]);

  useEffect(() => {
    if (!props.workout || typeof props.workout !== 'object') {
      return;
    }
    const interval = props.workout.intervals[currentInterval];
    const exercise = interval.exercises[currentExerciseIndex];
    console.log(exercise, currentExerciseIndex);
    setCurrentExercise(exercise);
    setRemainingTime(exercise.quantity);

    console.log(
      'current interval Length: ',
      interval.exercises.length,
      currentExerciseIndex,
    );
    if (currentExerciseIndex + 1 < interval.exercises.length) {
      console.log('loading next exercise');
      setNextExercise(interval.exercises[currentExerciseIndex + 1]);
    } else {
      if (currentSet + 1 < parseInt(interval.sets)) {
        setNextExercise(interval.exercises[0]);
      } else {
        if (props.workout.intervals[currentInterval + 1]) {
          setNextExercise(
            props.workout.intervals[currentInterval + 1].exercises[0],
          );
        } else {
          setNextExercise(null);
        }
      }
    }

    if (exercise.type === 'time') {
      startExerciseTimer(exercise);
    }
  }, [currentExerciseIndex, currentSet, currentInterval]);

  useEffect(() => {
    if (remainingTime > 0 && timer) {
      const countdown = setTimeout(() => {
        setRemainingTime(remainingTime - 1);
      }, 1000);
      return () => {
        clearTimeout(countdown);
      };
    } else if (
      remainingTime === 0 &&
      timer &&
      currentExercise &&
      currentExercise.type !== 'reps'
    ) {
      // Call setNextStep when the remaining time reaches zero and the exercise is not 'reps'
      setNextStep();
    }
  }, [remainingTime, timer, currentExercise]);

  const startExerciseTimer = (exercise) => {
    const exerciseTimer = setTimeout(() => {
      startRest(exercise);
    }, exercise.quantity * 1000);

    setTimer(exerciseTimer);

    return () => {
      clearTimeout(exerciseTimer);
    };
  };

  const startRest = (exercise) => {
    clearTimeout(timer);
    const rest = parseInt(exercise.restTime);
    console.log('rest: ', rest);
    setCurrentExercise({...exercise, exerciseName: 'Rest', quantity: rest});
    setRemainingTime(rest);

    const restTimer = setTimeout(() => {
      setNextStep();
    }, rest * 1000);

    setTimer(restTimer);

    return () => {
      clearTimeout(restTimer);
    };
  };

  const setNextStep = () => {
    setExercisesComplete((exercisesComplete) => exercisesComplete + 1); // Increment the number of exercises completed
    clearTimeout(timer);
    setTimer(null);
    const interval = props.workout.intervals[currentInterval];
    if (currentExerciseIndex + 1 < interval.exercises.length) {
      setCurrentExerciseIndex(currentExerciseIndex + 1);
    } else {
      setCurrentExerciseIndex(0);
      if (currentSet + 1 < parseInt(interval.sets)) {
        setCurrentSet(currentSet + 1);
      } else {
        setCurrentSet(0);
        if (currentInterval + 1 < props.workout.intervals.length) {
          setCurrentInterval(currentInterval + 1);
        } else {
          setCurrentInterval(0);
          setComplete(true);
        }
      }
    }
  };

  useEffect(() => {
    // Calculate the workout progress as a percentage
    setWorkoutProgress((exercisesComplete / totalExercises) * 100);
  }, [totalExercises, exercisesComplete]);

  return (
    <PageWrapper>
      <Box
        sx={{
          width: '80vw',
          pb: '2rem',
        }}>
        <LinearProgress
          sx={{
            height: '1.5rem',
            borderRadius: '1rem',
            backgroundColor: 'lightgray',
            '& .MuiLinearProgress-bar': {
              background: 'linear-gradient(90deg, #ee3189, #f36d21)',
            },
          }}
          variant="determinate"
          value={workoutProgress}
        />
      </Box>
      {currentExercise && !complete && (
        <>
          <IntervalTitle>
            {props.workout.intervals[currentInterval].intervalName} - set:{' '}
            {currentSet + 1}
          </IntervalTitle>
          {currentExercise.type === 'reps' &&
          currentExercise.exerciseName !== 'Rest' ? (
            <RepsWrapper>
              <ExerciseTitle>{currentExercise.exerciseName}</ExerciseTitle>
              <RepsCounterText>{`${currentExercise.quantity} reps`}</RepsCounterText>
              <StyledButton
                bright
                height={'7vh'}
                fontSize={'1.8rem'}
                onClick={() => startRest(currentExercise)}>
                Finished Reps
              </StyledButton>
            </RepsWrapper>
          ) : (
            <CountdownTimer
              remainingTime={remainingTime}
              duration={currentExercise.quantity}
              display={
                currentExercise.exerciseName === 'Rest'
                  ? 'Rest For'
                  : currentExercise.exerciseName
              }
            />
          )}
          {currentExercise.exerciseName === 'Rest' && nextExercise && (
            <NextExerciseText>
              Next Exercise: {nextExercise.exerciseName}
            </NextExerciseText>
          )}
        </>
      )}
      {complete && <h1>You're done!</h1>}
    </PageWrapper>
  );
};

export default WorkoutFlowComponent;
