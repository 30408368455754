import {useState, useEffect, useRef, memo} from 'react';
import {BackgroundImageWrapper, BackgroundImage} from './globalStyles';

const useProgressiveImg = (lowResSource, highResSource) => {
  const [src, setSrc] = useState(lowResSource);
  const [quickLoad, setQuickLoad] = useState(false);

  //Variable for timer to eliminate flicker on fast image load
  // useRef is used to store the timeUp value so that it can be accessed and updated inside the useEffect hook.
  // Since the useEffect hook doesn't have access to local variables and their changes, we need to use useRef
  // to create a mutable object that holds the timeUp value. This way, the useEffect hook can access the
  // current value of timeUp, ensuring the correct behavior when handling the image loading process.

  let timeUpRef = useRef(false);
  setTimeout(() => {
    timeUpRef.current = true;
  }, 100);

  useEffect(() => {
    setSrc(lowResSource);

    const img = new Image();
    img.src = highResSource;

    const unsubscribe = (img.onload = () => {
      setSrc(highResSource);
      if (!timeUpRef.current) {
        setQuickLoad(true);
      }
    });
    return unsubscribe;
  }, [lowResSource, highResSource]);

  // useEffect(() => {
  //   console.log("changed src");
  // }, [src])

  return [src, {blur: src === lowResSource, cancelAnimate: quickLoad}];
};

export default useProgressiveImg;

//Background component for the workout modal using progressiveImg
export const BlurredUpBackground = memo(({src, blur, cancelAnimate}) => {
  return (
    <BackgroundImageWrapper>
      <BackgroundImage src={src} blur={blur} cancelAnimate={cancelAnimate} />
    </BackgroundImageWrapper>
  );
});

// // Usage with comments:
// // Note that these styled components can be defined outside of the component that uses them.
// const filterStyle = (props) => {
//   if (props.blur) {
//     return css`
//       filter: blur(20px);
//     `;
//   } else if (props.cancelAnimate) {
//     return css`
//       filter: none;
//     `;
//   } else {
//     return css`
//       filter: none;
//       transition: filter 0.3s ease-in;
//     `;
//   }
// };
// const BackgroundImage = styled.img`
//   position: relative;
//   width: 100%;
//   height: 100%;
//   ${filterStyle}
//   filter: ${(props) => (props.blur ? 'blur(10px)' : 'none')};

//   /* object-position: 0% 90%; */
//   transform: scale(1.03);
//   object-fit: cover;
//   z-index: -2;
// `;
// const PageWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   padding-top: 15vh;
// `;

// const BackgroundImageWrapper = styled.div`
//   position: absolute;
//   width: 100%;
//   transform: translateY(36vh);
//   overflow: hidden;
//   z-index: -1;
// `;

// // Define a memoized BlurredUpBackground component that only re-renders if its props change.
// // This prevents unnecessary re-renders and fetching of the background image when not needed.
// // Memoization helps by comparing the previous and current props; if they are the same, React
// // will skip rendering the component and reuse the last rendered result.
// const BlurredUpBackground = memo(({ src, blur, cancelAnimate }) => {
//   // Render the BlurredUpBackground component with the received props.
//   return (
//     <BackgroundImageWrapper>
//       <BackgroundImage blur={blur} cancelAnimate={cancelAnimate} src={src} />
//     </BackgroundImageWrapper>
//   );
// });

// const Componenet = (props) => {
//   // ... (other code)

//   // Use the useProgressiveImg custom hook to fetch and manage the background images.
//   // This hook returns the current image src, a blur value, and a cancelAnimate function.
//   const [src, { blur, cancelAnimate }] = useProgressiveImg(lowResBRImg, fullResBRImg);

//   // Render the ComponentPage component.
//   return (
//     <PageWrapper>
//       // Use the memoized BlurredUpBackground component and pass the necessary props.
//       // By defining the component outside the ComponentPage and using memo(), we prevent
//       // unnecessary re-renders and image fetches when the form is updated.
//       <BlurredUpBackground src={src} blur={blur} cancelAnimate={cancelAnimate} />
//       {/* ... (other JSX elements) */}
//     </PageWrapper>
//   );
// };
